import React from 'react'
import ContactForm from '../components/ContactForm'
import Layout from '../components/layout'

import instaIcon from '../assets/icon/insta_icon.png';
import twitterIcon from '../assets/icon/twitter_icon.png';
import linkedinIcon from '../assets/icon/linkedin_icon.png';
import SocialsCard from '../components/SocialsCard';

export default function contact() {
    return (
        <Layout width_restrict_all={true} meta_title={'Contact'} meta_desc={'Contact Jonny using the contact form, his email address or his socials!'} meta_img={`/assets/metadata_images/default/contact.png`}>
            <h1 className='page-header'>Contact</h1>
            <p className='contact-page-preamble'>Please fill out the form below and I'll try and get back to you as soon as possible.  Alternatively, you can e-mail me directly at <a href='mailto:jonny@jonnymars.uk'>jonny@jonnymars.uk</a>.</p>
            <ContactForm classes='contact-page-contact-form' />
            <section id='socials-container'>
                <h2>Socials</h2>
                <div className='flex-container'>
                    <SocialsCard social_media_name='Twitter' icon={twitterIcon} url='https://twitter.com/JonnyMarsUK' username='@JonnyMarsUK' />
                    <SocialsCard social_media_name='Instagram' icon={instaIcon} url='https://www.instagram.com/jonnymars.uk/' username='@jonnymars.uk' />
                    <SocialsCard social_media_name='LinkedIn' icon={linkedinIcon} url='https://www.linkedin.com/in/jonnymarsuk/' username='jonnymarsuk' />
                </div>
            </section>
        </Layout>
    )
}
