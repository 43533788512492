import React from 'react';

import "../styles/components/_socials-card.scss";


export default function SocialsCard({social_media_name, icon, username, url}) {
    return (
        <a href={url} className='flex-container socials-card m-width-100'>
            <div className='socials-card-icon'>
                <img src={icon} alt={`${social_media_name} brand logo`} />
            </div>
            <div className='socials-card-username flex-container width-100'>
                {username}
            </div>
        </a>
    )
}
